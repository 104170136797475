import React from 'react'
import Layout from '../components/layout'

import bg from '../images/eat_fit_abq_meal_plans.png'

const Meals = () => (
  <Layout>
    <img
      style={{ position: 'absolute', right: 10, top: 10 }}
      src={bg}
      alt="background"
    />
    <div
      className="header"
      style={{
        position: 'absolute',
        left: 250,
        top: 40,
      }}
    >
      Weight Loss
    </div>
    <div
      style={{
        position: 'absolute',
        left: 250,
        top: 85,
        fontFamily: 'Verdana, Geneva, sans-serif',
        fontSize: 14,
        width: 290,
      }}
    >
      <div
        style={{
          textAlign: 'right',
          marginTop: 10,
          color: 'red',
          fontWeight: 'bold',
          fontSize: '1.25rem',
        }}
      >
        $11/meal
      </div>
      <p>
        (4 oz. of protein, vegetables &amp; carbs.)
        <br />
        <br />
        <span className="greentext">3 meals</span> per day, 5 days a week
        <br />
        $176.55 (tax included)
        <br />
        <br />
        <b>
          <i>Paleo Diet Also Offered</i>
        </b>
      </p>
    </div>
    <div
      className="header"
      style={{
        position: 'absolute',
        left: 250,
        top: 300,
      }}
    >
      Muscle Growth
    </div>
    <div
      style={{
        position: 'absolute',
        left: 250,
        top: 345,
        width: 290,
        fontFamily: 'Verdana, Geneva, sans-serif',
        fontSize: 14,
      }}
    >
      <div
        style={{
          textAlign: 'right',
          marginTop: 10,
          color: 'red',
          fontWeight: 'bold',
          fontSize: '1.25rem',
        }}
      >
        $12/meal
      </div>
      <p>
        (5.5 oz. of protein, vegetables &amp; carbs.)
        <br />
        <br />
        <span className="greentext">3 meals</span> per day, 5 days a week
        <br />
        $192.60 (tax included)
        <br />
        <br />
        <b>
          <i>Paleo Diet Also Offered</i>
        </b>
      </p>
    </div>
    <div
      style={{
        position: 'absolute',
        top: 565,
        left: 250,
        fontFamily: 'Verdana, Geneva, sans-serif',
        fontSize: 16,
      }}
    >
      <p>
        <b>
          <i>Create your own meal plan today! - 8 meals minimum</i>
        </b>
      </p>
    </div>
  </Layout>
)

export default Meals
